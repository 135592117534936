import React from 'react'
import './NuLoading.css'

const miselect={
    /*fontSize: "14pt",*/
    /*lineHeight: "1.5",*/
    border: "1px solid #ced4da",
    borderRadius: ".25rem",
    display: "block",
    width: "100%",
    padding: ".275rem .75rem",
}
const NuLoading = (props) => {
    //const {value, onChange} = props;
    const miClassName = `loading-mask caja_horizontal ${ props.className }`;

    return (
    <div >
        {/*<!-- loading -->*/}
        <div 
            className = {miClassName}
            style={{display: props.show ? 'inline-flex':'none' }}>
            <div className="loading-circle"></div>
            <h5 className="loading-content">{ props.text }</h5>
        </div>
        {/*<!-- -->*/}
    </div>
    )
};

export default NuLoading;