import { faRegistered } from '@fortawesome/free-solid-svg-icons'

import React, {useState} from 'react'

import { useForm } from 'react-hook-form'

const AddUserForm =(props) =>{

  const { register, handleSubmit, formState: { errors } } = useForm();

  const initialFormState = {id:null, name:'', username: ''}
  const [user, setUser] = useState(initialFormState)

  const onSubmit = (data, e) => {
    console.log(data)

    props.addUser(data);
    setUser(initialFormState);
    //limpiar campos
    e.target.reset();
  }

  return(
    <form onSubmit={handleSubmit(onSubmit)}>
      <label>Name</label>
      <input type="text" name="name" {
        ...register('name', {required: true})
      } />
      <div>
        {errors.name && errors.name.type === "required" && (
          <span role="alert">Campo requerido</span>
        )}
      </div>
      
      <label>Username</label>
      <input type="text" name="username" {
        ...register('username', {required: true})
      } />
      <div>
        {errors.username && errors.username.type === "required" && (
          <span role="alert">Campo requerido</span>
        )}
      </div>

      <button className='btn btn-primary'>Add new user</button>
    </form>
  )
}

export default AddUserForm;