import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
  } from "react-router-dom";
//import '../App.css';
import logo from '../assets/logo.png';
import UserTable from '../components/UserTable';

const Home =()=>{
    return(
        <div classNameName="">
            <img src={logo} alt="" width={200} />
            <h1>Acerca de</h1>
            
            <div className="row">
                <div className="col-md-4">
                    <div className="card p-3">
                        <div className="d-flex flex-row mb-3"><img src="https://i.imgur.com/ccMhxvC.png" width="70" />
                            <div className="d-flex flex-column ml-2"><span>Stripe</span><span className="text-black-50">Payment
                                    Services</span><span className="ratings"><i className="fa fa-star"></i><i
                                        className="fa fa-star"></i><i className="fa fa-star"></i><i
                                        className="fa fa-star"></i></span></div>
                        </div>
                        <h6>Get more context on your users with stripe data inside our platform.</h6>
                        <div className="d-flex justify-content-between install mt-3"><span>Installed 172 times</span><span
                                className="text-primary">View&nbsp;<i className="fa fa-angle-right"></i></span></div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card p-3">
                        <div className="d-flex flex-row mb-3"><img src="https://i.imgur.com/IpKQiNu.png" width="70" />
                            <div className="d-flex flex-column ml-2"><span>Mailchimp</span><span className="text-black-50">Project
                                    Management</span><span className="ratings"><i className="fa fa-star"></i><i
                                        className="fa fa-star"></i><i className="fa fa-star"></i><i
                                        className="fa fa-star"></i></span></div>
                        </div>
                        <h6>Capture and sync subscribers from your mailchimp platform to ours with ease.</h6>
                        <div className="d-flex justify-content-between install mt-3"><span>Installed 1234 times</span><span
                                className="text-primary">View&nbsp;<i className="fa fa-angle-right"></i></span></div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card p-3">
                        <div className="d-flex flex-row mb-3"><img src="https://i.imgur.com/42SqVZd.png" width="70" />
                            <div className="d-flex flex-column ml-2"><span>Dropbox</span><span className="text-black-50">File
                                    Management</span><span className="ratings"><i className="fa fa-star"></i><i
                                        className="fa fa-star"></i><i className="fa fa-star"></i><i
                                        className="fa fa-star"></i></span></div>
                        </div>
                        <h6>Use dropbox to sync your photos to our platform and share it with others.</h6>
                        <div className="d-flex justify-content-between install mt-3"><span>Installed 1234 times</span><span
                                className="text-primary">View&nbsp;<i className="fa fa-angle-right"></i></span></div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card p-3">
                        <div className="d-flex flex-row mb-3"><img src="https://i.imgur.com/lXEUCY8.png" width="70" />
                            <div className="d-flex flex-column ml-2"><span>Shopify</span><span
                                    className="text-black-50">Ecommerce</span><span className="ratings"><i
                                        className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i
                                        className="fa fa-star"></i></span></div>
                        </div>
                        <h6>Sell you items with ease with having any website with our free platform</h6>
                        <div className="d-flex justify-content-between install mt-3"><span>Installed 1234 times</span><span
                                className="text-primary">View&nbsp;<i className="fa fa-angle-right"></i></span></div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card p-3">
                        <div className="d-flex flex-row mb-3"><img src="https://i.imgur.com/S2In5pz.png" width="70" />
                            <div className="d-flex flex-column ml-2"><span>Atlassian</span><span className="text-black-50">Project
                                    management</span><span className="ratings"><i className="fa fa-star"></i><i
                                        className="fa fa-star"></i><i className="fa fa-star"></i><i
                                        className="fa fa-star"></i></span></div>
                        </div>
                        <h6>Developing products for developers,project managers and architects.</h6>
                        <div className="d-flex justify-content-between install mt-3"><span>Installed 345 times</span><span
                                className="text-primary">View&nbsp;<i className="fa fa-angle-right"></i></span></div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card p-3">
                        <div className="d-flex flex-row mb-3"><img src="https://i.imgur.com/S5oK3Oe.png" width="70" />
                            <div className="d-flex flex-column ml-2"><span>Salesforce</span><span className="text-black-50">Project
                                    management</span><span className="ratings"><i className="fa fa-star"></i><i
                                        className="fa fa-star"></i><i className="fa fa-star"></i><i
                                        className="fa fa-star"></i></span></div>
                        </div>
                        <h6>Developing products for developers,project managers and architects.</h6>
                        <div className="d-flex justify-content-between install mt-3"><span>Installed 345 times</span><span
                                className="text-primary">View&nbsp;<i className="fa fa-angle-right"></i></span></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;