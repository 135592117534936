import React from 'react'
import Nav from '../components/Common/Header'

import Navbar2 from '../components/Navbar2'
import Sidebar from '../components/Sidebar'

import { Outlet } from "react-router-dom";

// eslint-disable-next-line import/no-anonymous-default-export
const MainAdmin = ({  }) => {

    console.log('render Main')

    return (
        <div>
            
            
            <div className='flex'>
                <Sidebar />
                <div className='content w-100'>
                    <Navbar2 />
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default MainAdmin;