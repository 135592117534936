

const initialStore ={
    user: { 
            "id_u": 1,
            "nomape": "",
            "username": "",
            "id_rol": 0,
            "rol_nom": "",
            "permisos":0,
            "id_ca": 0,
            "foto_path1": "",
        },
    paciente:{
        "id_paciente": 0,
        "nombre":'s/n',
        "foto_path1": "",
        "fecha_nac": "",
    },
    products:[
        {id:1, title: 'product #1'},
        {id:2, title: 'product #2'},
    ]
}
//---------------------------------------
const types ={
        authLogin: 'auth-login',
        authLogout: 'auth-logout',
        setPaciente: 'paciente-set',
        productDeleteAll: 'product-delete all',
}
//---------------------------------------------------------

//---------------------------------------------------------
const storeReducer = (state, action) => {
    switch (action.type){
        case types.authLogout: //LOGOUT
            return{
                ...state,
                user: null
            }
        case types.authLogin: //LOGIN
            return{
                ...state,
                user: action.payload
            }

        case types.setPaciente: // establece valores de paciente
            // save data on every change
            //localStorage.setItem("_paciente_", JSON.stringify(action.payload));
            //---------------
            return{
                ...state,
                paciente: action.payload
            } 
        case types.productDeleteAll:
            return{
                ...state,
                products:[]
            }
        case types.productChange:
            return{
                ...state,
                products:[{id:3, title:'Product #3'}]
            }
        default:
            return state;
    }
}

export  { types }
export  { initialStore }
export default storeReducer;
