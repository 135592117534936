import axios from "axios";
import AuthService from "./auth.service";
import { useNavigate } from 'react-router-dom';

import Swal from "sweetalert2";

const axios2 = axios.create({
  baseURL: process.env.REACT_APP_API_URL ,
  headers: {
    "Content-Type": "application/json",
  },
});

//--- colocamos el encabezado un interceptor a las llamadas axios
//--- antes de llamar 
axios2.interceptors.request.use(
  (config) => {
    const token = AuthService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      //config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


axios2.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    //const navigate = useNavigate();
    //--si la pagina que solicita es la de login
    // Error 403 Forbidden
    // 401 indica que la petición (request) no ha sido ejecutada porque carece de credenciales válidas
    //const originalConfig = err.config;//originalConfig.url
    /*console.log("=?=============>");
    console.log(err.response);
    alert(err);*/
    if (err.response.status === 401){
      //Swal.fire("-- Error 401 Unauthorized --");
      console.log("axios2 - error 401");
      Swal.fire({
        title: "-- Error 401 Unauthorized --",
        text: "",
        type: 'success',
        confirmButtonText: "Ir a Login",
        cancelButtonText: "Cancel",
      }).then((result) => {
        //return MySwal.fire(<p>Shorthand works too</p>)
        if (result.isConfirmed) {
          //Swal.fire('Nice to meet you', '', 'success');
          AuthService.removeToken();
          return window.location.href = '/login';
        }
        //navigate("/login");
      })
      
    } 
    if(err.response.status === 400) {
      Swal.fire("-- (HTTP) 400 Bad Request --");
    }
    return Promise.reject(err);
  }
);
export default axios2;
