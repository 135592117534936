import React, {useState} from "react";
import{
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,DropdownMenu,
  DropdownItem,
  NavbarText
} from 'reactstrap';

const Navbar2 =(props) =>{
  const [isOpen, setIsOpen] = useState(false);

  const toggle= () =>setIsOpen(!isOpen);
  return (
    <div >
      <Navbar color="light" light expand="md">
      <NavbarBrand href="/">reactstrap</NavbarBrand>
      <NavbarToggler onClick={toggle}/>
      <Collapse isOpen={isOpen} navbar>
        {/* a la derecha alineado */}
        <Nav className="ms-auto" navbar>
          <NavItem>
            <NavLink href="/components/">Components</NavLink>
          </NavItem>

          

          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              Diego Velazques
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem>
                Mi perfil
              </DropdownItem>
              <DropdownItem>
                Configuracion
              </DropdownItem>

              <DropdownItem divider/>
              <DropdownItem>
                Cerrar sesion
              </DropdownItem>

            </DropdownMenu>
          </UncontrolledDropdown>

        </Nav>
        
      </Collapse>
      
      </Navbar>
    </div>
  );
}

export default Navbar2;