import React, {useState} from 'react';
import {
    Link,
   
  } from "react-router-dom";
//import '../App.css';
import crud from '../assets/crud.gif';

import Tabla2 from '../components/Tabla2';

const data = [
  { id: 1, personaje: "Naruto", anime: "Naruto" },
  { id: 2, personaje: "Goku", anime: "Dragon Ball" },
  { id: 3, personaje: "Kenshin Himura", anime: "Rurouni Kenshin" },
  { id: 4, personaje: "Monkey D. Luffy", anime: "One Piece" },
  { id: 5, personaje: "Edward Elric", anime: "Fullmetal Alchemist: Brotherhood"},
  { id: 6, personaje: "Seto Kaiba", anime: "Yu-Gi-Oh!" },
];

const CrudModal =()=>{
    
    //-- agregar usuarios
    
    return(
        <div className="container">
            <img src={crud} alt="" width={200}  />
            <h1>CRUD Modal</h1>
            <Tabla2/>
        </div>
    );
}

export default CrudModal;