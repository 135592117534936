import { createContext, useContext, useReducer,useEffect } from 'react'
import storeReducer, { initialStore } from './StoreReducer'

const StoreContext = createContext();

const StoreProvider =({children}) =>{
  const STORAGE_KEY = 'MY_DATA'

  // load data initially
  //const [store, dispatch] = useReducer( storeReducer, initialStore)

  //------ load data initially - con Persistencia - jimmy 16-02-2023
  const [store, dispatch] = useReducer(storeReducer, initialStore, (initialValue = initialStore) => {
    return JSON.parse(localStorage.getItem(STORAGE_KEY)) || initialValue;
  })
  //----- guardamos en el localstorage en cada cambio
  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(store))
  }, [store])

  return (
    <StoreContext.Provider value = {[store, dispatch]}>
        { children }
    </StoreContext.Provider>
  )
}
//-------------------------------------------
//---------------------------------------
const useStore = () => useContext(StoreContext)[0];
const useDispatch = () => useContext(StoreContext)[1];

export { StoreContext, useStore, useDispatch }
export default StoreProvider;