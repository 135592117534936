import React, {useState} from 'react';
import {
    Link,
   
  } from "react-router-dom";
//import '../App.css';
import loader from '../assets/loader.gif';
import UserTable from '../components/UserTable';
import AddUserForm from '../components/AddUserForm';
import EditUserForm from '../components/EditUserForm';


const List_crud =()=>{
    
    const usersData=[
        {id:1, name:'Tania', username:'floopydiskette'},
        {id:2, name:'Craig', username:'silicon'},
        {id:3, name:'Ben', username:'benisphere'},
    ]

    //--state
    const [users, setUsers] = useState(usersData);

    //-- agregar usuarios
    const addUser = (user) =>{
        user.id =  users.length+1;
        setUsers([...users, user]);
    }

    //-- eliminar usuario
    const deleteUser = (id) =>{
        console.log(id);
        //se hace filtro para eliminar
        //cuando el user.id es distinto de id,  lo va filtrar
        setUsers(users.filter(user => user.id !== id ))
    }
    
    //-- editar usuario
    const [editing, setEditing] = useState(false)
    const initialFormState ={id: null, name:'', username:''}
    //--usuario actual
    const [currentUser, setCurrentUser]= useState(initialFormState)

    const editRow = user =>{
        setEditing(true)
        setCurrentUser({id: user.id, name: user.name, username: user.username })
    }

    const updateUser = (id, updateUser) =>{
        setEditing(false)
        setUsers(users.map(user=>(user.id === id? updateUser: user)))
    }
    return(
        <div className="container">
            <img src={loader} alt="" />
            <h1>CRUD App with Hooks</h1>
            <div className='flex-row'>
                <div className='flex-large'>

                    {
                        editing ? (
                            <div>
                                <h2>Edit user</h2>
                                <EditUserForm currentUser={currentUser} udpateUser={updateUser}/>
                            </div>
                        ):(
                            <div>
                               <h2>Add user</h2>
                                <AddUserForm addUser={addUser} />
                            </div>
                        )
                    }
                    

                    
                </div>
                <div className='flex-large'>
                    <h2>View users</h2>
                    <UserTable 
                        users= {users} 
                        deleteUser={deleteUser} 
                        setEditing={setEditing} 
                        editRow={editRow}
                    />
                </div>
            </div>
        </div>
    );
}

export default List_crud;