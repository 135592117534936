import React from "react";

import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
} from "reactstrap";

const InputBox =(props) =>{
  /*const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);*/

  return(
    <Modal isOpen={ props.modalShow}>
    <ModalHeader>
     <div><h3>{props.titulo}</h3></div>
    </ModalHeader>

    <ModalBody>
      
      <FormGroup>
        <label>
          {props.mensaje}
        </label>
        <input
          className="form-control"
          name="personaje"
          type="text"
          onChange={props.handleChange}
          value={props.value}
        />
      </FormGroup>
      
    </ModalBody>

    <ModalFooter>
      <Button
        color="primary"
        onClick={() => props.handleOK()}
      >
        Aceptar
      </Button> &nbsp;&nbsp;
      <Button
        color="danger"
        onClick={() => { props.handleCancel()}}
      >
        Cancelar
      </Button>
    </ModalFooter>
  </Modal>
  )
}
export default InputBox;