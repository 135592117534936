
import axios from "axios";
import axios2 from "./axios2";

//--------------------------------------------------------------
const signup = (name, email, password, password_confirmation ) => {
  //const API_URL = "/auth";

  //console.log(config.API_URL + "register");
  const param = { 'name': name, 'email': email, 'password':password, 
                  'password_confirmation':password_confirmation };

  
  return axios
    .post(process.env.REACT_APP_API_URL  + "register", param)
    .then((response) => {
      /*if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }*/
      //---no escribimos en el localStorage
      //console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log("err ajax");
      console.log(error);
    });;
};
//--------------------------------------------------------------
// 3600 segundos = 3600/60 = 60min
const login = (email, password) => {
  //console.log(process.env.REACT_APP_API_URL);

  return axios
    .post(process.env.REACT_APP_API_URL + "login", {
      email,
      password,
    })
    .then((response) => {
      console.log(response);
      if (response.data.access_token) {
        //guardamos el usuario en el localstorage
        localStorage.setItem("user", JSON.stringify(response.data.user));
        //localStorage.setItem("token", JSON.stringify(response.data.access_token));
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("expires_in", response.data.expires_in);
        //---------------------------- guardamos fecha de expiracion 
        var now = new Date();
        let expires_in = response.data.expires_in;
        let expirationDate = new Date(now.getTime() + expires_in*1000);
        localStorage.setItem("expirationDate",expirationDate );
        //console.log(expirationDate);
      }

      return response.data;
    });
};
//--------------------------------------------------------------
const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  //------------ actualizamos el estado global

};
const removeToken = () => {
  localStorage.removeItem("token");
};
//-------------------------------------------------------------------------------
//---enviamos al servidor para que nos mande un nuevo token
const refreshToken =()=>{
  return axios2
  .post(process.env.REACT_APP_API_URL  + "refresh", {
    
  })
  .then((response) => {
    console.log(response.data);
    let expiration  =null;
    if (response.data.access_token) {
      
      //localStorage.setItem("token", JSON.stringify(response.data.access_token));
      localStorage.setItem("token", response.data.access_token);
      localStorage.setItem("expires_in", response.data.expires_in);
      //---------------------------- guardamos fecha de expiracion 
      var now = new Date();
      let expires_in = response.data.expires_in;
      let expirationDate = new Date(now.getTime() + expires_in*1000);
      localStorage.setItem("expirationDate",expirationDate );
      //console.log(expirationDate);
      expiration= expirationDate;
     
    }

    return expiration;
  });
}
//-------------------------------------------------------------------------
const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const getLocalAccessToken = () => {
  return localStorage.getItem("token");
}

const getExpirationDate = () => {
  return localStorage.getItem("expirationDate");
}

const runLogoutTimer=(dispatch, timer)=>{
  setTimeout(()=>{
    dispatch(logout());
  },timer);
}
 

const authService = {
  signup,
  login,
  logout,
  removeToken,
  getCurrentUser,
  getLocalAccessToken,
  getExpirationDate,
  refreshToken,
  runLogoutTimer,
};

export default authService;