import { faRegistered } from '@fortawesome/free-solid-svg-icons'

import React, {useState} from 'react'

import { useForm } from 'react-hook-form'


const EditUserForm =(props) =>{

  console.log(props);

  //---------inicializamos con useForm
  const { register, handleSubmit,setValue, formState: { errors } } = useForm({
    defaultValues: props.currentUser
  });

  setValue('name',props.currentUser.name)
  setValue('usename',props.currentUser.username)

  const initialFormState = {id:null, name:'', username: ''}
  const [user, setUser] = useState(initialFormState)

  const onSubmit = (data, e) => {
    console.log(data)

    data.id = props.currentUser.id;

    props.udpateUser(props.currentUser.id, data);
    setUser(initialFormState);
    //limpiar campos
    e.target.reset();
  }

  return(
    <form onSubmit={handleSubmit(onSubmit)}>
      <label>Name</label>
      <input type="text" name="name" {    ...register('name', {required: true})   } />
      <div>
        {errors.name && errors.name.type === "required" && (
          <span role="alert">Campo requerido</span>
        )}
      </div>
      
      <label>Username</label>
      <input type="text" name="username" {  ...register('username', {required: true})  } />
      <div>
        {errors.username && errors.username.type === "required" && (
          <span role="alert">Campo requerido</span>
        )}
      </div>

      <button className='btn btn-primary'>Editar usuario</button>
    </form>
  )
}

export default EditUserForm;